import { useEffect, useRef, useState } from "react"
import useSocialSharei18n from "@/i18n/useSocialSharei18n"
import {
  getShortenedUrl,
  getShortenedUrlwithGeneral,
  sanitizeUrl,
} from "@/utils/helper"
import { getConfig } from "@/constants/config"
import _get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import useIsSsr from "@/hooks/useIsSsr"
const SocialShare = props => {
  const {
    url = "",
    showModal,
    componentProp: {
      assetUrl = "",
      url: compUrl = "",
      internallinkposition,
      internallinktype,
      internallinkzonename,
      eventaction,
      eventname,
    } = {},
    componentProp,
    id,
    sharedCartNum,
    onClose,
    mailBody,
    mailSubject,
    asset,
    analyticData: analyticPropData,
    iframeId,
    shareBtn,
    shareRef,
  } = props
  const isSsr = useIsSsr()
  const [sites, setSites] = useState([])
  const [general, setGeneral] = useState(null)
  const [origin, setOrigin] = useState(null)
  const [href, setHref] = useState(null)
  const [socialMediaConfigs, setSocialMediaConfigs] = useState({})
  const emailBtnRef = useRef(null)
  const copyBtnRef = useRef(null)
  const shareBoxRef = useRef(null)
  const brandName = useRef("")
  const shareCartUrl = useRef("")
  const socialShareCopyIconPath = useRef("")
  const socialShareEmailIconPath = useRef("")
  useEffect(() => {
    if (!isSsr) {
      setOrigin(window.location.origin)
      setHref(window.location.href)
    }
  }, [isSsr])
  useEffect(() => {
    getConfig().then(async config => {
      setGeneral(config?.general)
      const socialMediaConfigs = _get(config, "socialMediaConfig", {})
      setSocialMediaConfigs(socialMediaConfigs)
      brandName.current = _get(config, "general?.siteName", "")
      socialShareCopyIconPath.current = _get(
        config,
        "general.socialShareCopyIconPath",
        ""
      )
      socialShareEmailIconPath.current = _get(
        config,
        "general.socialShareEmailIconPath",
        ""
      )
      shareCartUrl.current = await getShortenedUrl(
        _get(config, "general.shareCartPagePath", "")
      )
    })
  }, [])

  // get component social share link url
  const getLink = () => {
    const isFullUrl = /^https?:\/\//i
    if (url && isFullUrl.test(url)) {
      return url
    }
    if (url) {
      return `${origin}${url}`
    } else if (assetUrl && compUrl) {
      // teaser video component link all cases
      if (!isFullUrl.test(compUrl)) {
        const newcompUrl = getShortenedUrlwithGeneral(compUrl, general)
        return `${origin}${newcompUrl}`
      } else {
        return compUrl
      }
    } else if (assetUrl) {
      return assetUrl
    } else {
      return sanitizeUrl(href ?? window.location.href)
    }
  }
  const link = getLink()
  const staticText = useSocialSharei18n(sharedCartNum, brandName)

  const [copyIcon, setCopyIcon] = useState(false)

  useEffect(() => {
    let siteList = []
    socialMediaConfigs?.myArrayList?.forEach(socialMediaConfig => {
      if (!isEmpty(socialMediaConfig.map) && socialMediaConfig.map.name) {
        const site = {
          key: socialMediaConfig.map.name ?? "",
          icon: socialMediaConfig.map.iconPath ?? "",
        }

        const url = socialMediaConfig.map.redirectUrl ?? ""
        if (socialMediaConfig.map.name.toLowerCase().includes("twitter")) {
          siteList.push({
            ...site,
            url: encodeURI(url + link + `&text=${document.title}`),
          })
        } else if (
          socialMediaConfig.map.name.toLowerCase().includes("pinterest")
        ) {
          if (asset)
            siteList.push({
              ...site,
              url:
                url +
                `${origin}&media=${asset.url}&description=${asset.description}`,
            })
        } else {
          siteList.push({
            ...site,
            url: url + link,
          })
        }
      }
    })
    if (siteList.length) {
      siteList = siteList.filter(
        site => !site.key.toLowerCase().includes("instagram")
      )
    }
    setSites(siteList)
  }, [socialMediaConfigs])

  const emailLink = e => {
    e.stopPropagation()
    addDataLayer("email a friend", "n/a")
  }

  const socialLinks = e => {
    const target = e.target

    const nodeUrl = target.hostname.replace(/(^www\.)/, "")
    if (
      !window.excludUrl ||
      (window.excludUrl && !window.excludUrl.includes(nodeUrl))
    ) {
      e.stopPropagation()
      e.preventDefault()
      if (window.handleShowModal) window.handleShowModal(target.href, target)
    } else if (target.href) {
      window.open(target.href)
    }
    const key = target.siteKey || nodeUrl || target.hostname
    try {
      addDataLayer(key.split(".com")[0], target.href)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    copyBtnRef?.current?.addEventListener("click", copyLink, false)
    shareBoxRef?.current?.addEventListener("click", shareBox, false)
    emailBtnRef?.current?.addEventListener("click", emailLink, false)
    if (shareBoxRef?.current) {
      Array.from(
        shareBoxRef.current.getElementsByClassName("kf-social-links")
      )?.forEach(link => {
        link?.addEventListener("click", socialLinks, false)
      })
    }
    return () => {
      // garbage collection
      copyBtnRef?.current?.removeEventListener("click", copyLink, false)
      shareBoxRef?.current?.removeEventListener("click", shareBox, false)
      emailBtnRef?.current?.removeEventListener("click", emailLink, false)
      if (shareBoxRef?.current) {
        Array.from(
          shareBoxRef.current.getElementsByClassName("kf-social-links")
        )?.forEach(link => {
          link?.removeEventListener("click", socialLinks, false)
        })
      }
    }
  }, [sites, showModal])

  const analyticData = analyticPropData || {
    internalLinkPosition: internallinkposition || "n/a",
    internalLinkType: internallinktype || "n/a",
    internalLinkZoneName: internallinkzonename || "n/a",
    eventAction: eventaction || "n/a",
    eventName: eventname || "n/a",
  }

  const handleClick = e => {
    const selELement = iframeId
      ? document.getElementById(iframeId).contentDocument.getElementById(id)
      : document.getElementById(id)
    if (
      showModal &&
      !selELement?.contains(e.target) &&
      !shareBtn?.current?.contains(e.target)
    ) {
      onClose()
    }

    if (
      componentProp?.showModal &&
      window.shareSrcElement !== null &&
      window.shareSrcElement !== undefined &&
      !shareRef.current.contains(e.target)
    ) {
      const shareElems = [...document.getElementsByClassName("share-cmp")]
      shareElems.forEach(el => {
        el.style.display = "none"
      })
      window.shareSrcElement = null
    }
    return true
  }

  const handleClose = e => {
    if (e.keyCode === 27) {
      onClose()
      shareBtn.current.focus()
    }
  }

  useEffect(() => {
    const selELement = iframeId
      ? document.getElementById(iframeId).contentDocument
      : document
    if (showModal) {
      selELement.addEventListener("click", handleClick)
      selELement.addEventListener("keydown", handleClose)
      return () => {
        selELement.removeEventListener("click", handleClick)
        selELement.removeEventListener("keydown", handleClose)
      }
    }
    if (componentProp?.showModal && showModal) {
      document.addEventListener("click", handleClick)
    }
  }, [showModal])

  const copyLink = e => {
    e.stopPropagation()
    e.preventDefault()
    navigator.clipboard.writeText(link)
    setCopyIcon(true)
    setTimeout(() => {
      setCopyIcon(false)
    }, 3000)
    addDataLayer("copy link", link ?? "n/a")
  }

  const shareBox = e => {
    e.stopPropagation()
    e.preventDefault()
  }

  const addDataLayer = (siteKey, link) => {
    const {
      eventAction = "n/a",
      eventName = "n/a",
      eventType = "informative",
      eventMsg = "n/a",
      eventStatus = "n/a",
      internalLinkPosition = "n/a",
      internalLinkType = "n/a",
      internalLinkZoneName = "n/a",
      internalLinkURL,
      custom,
    } = analyticData

    const eventInfo = {
      eventAction,
      eventName,
      eventType,
      eventMsg,
      eventStatus,
      internalLinkName: siteKey,
      internalLinkPosition,
      internalLinkType,
      internalLinkZoneName,
      internalLinkURL: link || internalLinkURL || "n/a",
      clickInternalLinks:
        siteKey === "copy link" || siteKey === "email a friend"
          ? "true"
          : "false",
    }

    if (window.dataLayerEventType) {
      eventInfo.internalLinkType = window.dataLayerEventType
    }

    if (window.dataLayerEventZoneName) {
      eventInfo.internalLinkZoneName = window.dataLayerEventZoneName
      if (eventAction === "n/a") {
        eventInfo.eventAction = window.dataLayerEventZoneName + ":share"
        eventInfo.eventName = window.dataLayerEventZoneName + ":share"
      }
    }

    const { adobeDataLayer: { getState } = {} } = window
    const page = (getState && getState("page")) || {}
    let productInfo
    if (custom) {
      try {
        productInfo = JSON.parse(custom).productInfo
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }
    window.adobeDataLayer.push({
      event: "cmp:click",
      page,
      eventInfo,
      productInfo,
    })
  }

  return showModal ?? componentProp?.showModal ? (
    <div className="social-share" id={id} ref={shareRef}>
      <div className="social-share__box" ref={shareBoxRef}>
        <div className="social-share__sites">
          {sharedCartNum && sharedCartNum ? (
            <div className="social-share__shared-cart-number">
              {staticText.yourSharedCartNumber}
            </div>
          ) : (
            <ul role="list">
              {sites.map((site, i) => {
                return (
                  <li key={i} role="listitem">
                    <a
                      id={`kf-social-links-${i}`}
                      rel="noreferrer"
                      href={site.url}
                      className="kf-social-links"
                      aria-label={`${staticText.linksCTA(site.key)} ${
                        staticText.openNewTabText
                      }`}
                      target="_blank"
                      data-link-type="social media"
                    >
                      <img
                        aria-hidden="true"
                        src={site?.icon}
                        alt={site.key}
                        className={`social-share__${site.key}`}
                      />
                    </a>
                  </li>
                )
              })}
            </ul>
          )}
        </div>
        <div className="social-share__actions" role="list">
          <div role="listitem">
            <div
              tabIndex="0"
              role="button"
              ref={copyBtnRef}
              className="social-share__cta"
            >
              <img
                className="social-share__copy-link-icon"
                aria-hidden
                src={socialShareCopyIconPath.current}
                role="presentation"
                alt=""
              />
              {copyIcon ? staticText.copyLinkIndication : staticText.copyLink}
            </div>
          </div>
          <div role="listitem">
            <a
              className="social-share__cta social-share__email"
              aria-label={`${staticText.email} ${staticText.openNewTabText}`}
              onKeyDown={() => onClose()}
              ref={emailBtnRef}
              href={sanitizeUrl(
                `mailto:?body=${(mailBody ? mailBody : "") + link}${
                  mailSubject ? "&subject=" + mailSubject : ""
                }`
              )}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={socialShareEmailIconPath.current}
                role="presentation"
                alt=""
              />
              {staticText.email}
            </a>
          </div>
        </div>
      </div>
    </div>
  ) : null
}
export default SocialShare
